import axios from "axios";

const RESSOURCE_NAME = "collectes";

export default {
  create(collecte) {
    return axios.post(`${RESSOURCE_NAME}/create`, collecte);
  },
  update(collecte, id_collecte) {
    return axios.patch(`${RESSOURCE_NAME}/update/${id_collecte}`, collecte);
  },

  getType() {
    return axios.get(`${RESSOURCE_NAME}/types`)
  },

  getCollecte(id_collecte) {
    return axios.get(`${RESSOURCE_NAME}/${id_collecte}`)
  },

  deleteCollecte(id_collecte, commentaire) {
    console.log("api on ets dand le commentaire", commentaire,id_collecte );
    return axios.patch(`${RESSOURCE_NAME}/delete/${id_collecte}`,{commentaire:commentaire})
  },

  removeCollecte(id_collecte) {
    return axios.patch(`${RESSOURCE_NAME}/remove/${id_collecte}`)
  },

  getPlanning(date) {
    return axios.get(`${RESSOURCE_NAME}/planning/${date}`)
  },

  modifyCollecteState(id_collecte, collecte) {
    return axios.patch(`${RESSOURCE_NAME}/modifystate/${id_collecte}`, {
      type: collecte.type,
      commentaire: collecte.commentaire,
      possede_carte: collecte.possede_carte
    });
  },

};
